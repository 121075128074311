<template>
  <v-dialog v-model="dialog" scrollable persistent full-width>
    <v-card>
      <v-card-title class="headline" v-if="view.id">
        {{ $t('user.edittitle') }}
      </v-card-title>
      <v-card-title class="headline" v-if="!view.id"
        >{{ $t('user.addtitle') }}
      </v-card-title>
      <v-card-text>
        <v-form v-model="valid" lazy-validation>
          <v-container fluid>
            <v-row align="center">
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="view.sur"
                  :label="$t('user.surname')"
                  required
                  dense
                  hide-details
                  :rules="[(v) => !!v || $t('all.fieldnoempty')]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="view.name"
                  :label="$t('user.name')"
                  required
                  dense
                  hide-details
                  :rules="[(v) => !!v || $t('all.fieldnoempty')]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="view.patronymic"
                  :label="$t('user.patname')"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="view.phone"
                  :label="$t('all.phone')"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="view.mz"
                  :label="$t('user.mzcode')"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="view.filter_ip"
                  dense
                  hide-details
                  :label="$t('user.ipfilter')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="view.login"
                  :label="$t('user.login')"
                  required
                  dense
                  hide-details
                  :rules="[(v) => !!v || $t('all.fieldnoempty')]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="view.pass"
                  :label="$t('user.pass')"
                  required
                  dense
                  hide-details
                  :rules="[(v) => !!v || $t('all.fieldnoempty')]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-select
                  v-bind:items="salarys"
                  v-model="view.salary"
                  :label="$t('user.salary')"
                  bottom
                  item-text="name"
                  item-value="id"
                  dense
                  hide-details
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col sm="6">
                <v-card outlined rounded>
                  <v-card-text>
                    <p>{{ $t('user.rules') }}</p>
                    <v-switch
                      v-for="item in rules"
                      v-bind:key="item.id"
                      v-model="item.checked"
                      v-bind:label="item.name"
                      color="primary"
                      hide-details
                    ></v-switch>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col sm="6">
                <v-card outlined rounded>
                  <v-card-text>
                    <p>{{ $t('user.adrs') }}</p>
                    <v-switch
                      v-for="item in adrs"
                      v-bind:key="item.id"
                      v-model="item.checked"
                      v-bind:label="item.name"
                      color="primary"
                      hide-details
                    ></v-switch>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" v-if="btnDel" @click.native="del" small>
          {{ $t('all.delete') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn @click.native="$emit('close')" small>{{
          $t('all.close')
        }}</v-btn>
        <v-btn color="primary" dark @click.native="save" small>{{
          $t('all.save')
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['title', 'view'],
  data() {
    return {
      dialog: true,
      valid: false,
      rules: [],
      adrs: [],
      errSur: false,
      errName: false,
      errPass: false,
      errRule: false,
      errAdr: false,
      errLogin: false,
      salarys: [],
      btnDel: false,
    }
  },
  computed: {},
  mounted: function () {
    var t = this,
      user = this.view.id ? this.view.id : ''
    this.btnDel = this.view.id ? true : false
    this.$http.post(this.$store.state.apiUrl + 'user/rule/' + user).then(
      (response) => {
        t.rules = response.body
      },
      (err) => {
        console.log(err)
      }
    )
    this.$http.post(this.$store.state.apiUrl + 'user/adr/' + user).then(
      (response) => {
        t.adrs = response.body
      },
      (err) => {
        console.log(err)
      }
    )
    this.$http.post(this.$store.state.apiUrl + 'salary/group/').then(
      (response) => {
        t.salarys = response.body
      },
      (err) => {
        console.log(err)
      }
    )
  },
  methods: {
    del: function () {
      this.$emit('delete', this.view)
    },
    save: function () {
      var t = this,
        i = 0,
        a = 0,
        prmRes = [],
        prmAdr = []
      var arr = Object.assign({}, this.view)

      for (var res in this.rules) {
        if (this.rules[res].checked == true) {
          prmRes[i] = this.rules[res].id
          i++
        }
      }

      for (res in this.adrs) {
        if (this.adrs[res].checked == true) {
          prmAdr[a] = this.adrs[res].id
          a++
        }
      }
      /*
      if (i == 0) {
        this.errRule = 'Должна быть выбрана хотябы одна роль'
      } else {
        this.errRule = false
      }
      if (a == 0) {
        this.errAdr = 'Должна быть выбрана хотябы одна мастерская'
      } else {
        this.errAdr = false
      }*/
      if (this.valid) {
        t.$store.commit('loadingStartShow', true)
        this.$http
          .post(this.$store.state.apiUrl + 'user/save/', {
            id: this.irule,
            user: arr,
            rules: prmRes,
            adrs: prmAdr,
          })
          .then(
            (response) => {
              t.$store.commit('loadingStartShow', false)
              t.$store.dispatch('setMessage', {
                type: response.body.err == 1 ? 'error' : 'success',
                message: t.$t(response.body.msg),
              })
              if (!response.body.err) {
                this.$emit('close', 'reload')
              }
            },
            (err) => {
              console.log(err)
            }
          )
      }
    },
  },
}
</script>

<style></style>
