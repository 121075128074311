<template>
  <div>
    <v-row>
      <v-col>
        <h3>{{ $t('user.title') }}</h3>
      </v-col>
      <v-col class="text-right">
        <v-btn outlined small color="primary" @click="link('rule')">{{ $t('rule.title') }}</v-btn>
      </v-col>
    </v-row>
    <br />
    <v-divider></v-divider> <br />
    <v-btn color="success" @click="addUser" small> <v-icon dark>fa-plus</v-icon>{{ $t('all.add') }} </v-btn>
    <br /><br />
    <v-simple-table dense>
      <thead>
        <tr>
          <th class="text-left">{{ $t('user.fio') }}</th>
          <th class="text-left">{{ $t('user.login') }}</th>
          <th class="text-left">{{ $t('user.salary') }}</th>
          <th class="text-left">{{ $t('user.rule') }}</th>
          <th class="text-left">{{ $t('user.adr') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in tableData" :key="item.login" @click="editUser(item)" style="cursor: pointer">
          <td>{{ item.fio }}</td>
          <td>{{ item.login }}</td>
          <td>{{ item.salary_name }}</td>
          <td>{{ item.rules }}</td>
          <td>{{ item.adrs }}</td>
        </tr>
      </tbody>
    </v-simple-table>

    <modal_edit
      v-if="modal"
      v-bind:title="modalTitle"
      v-bind:view="modalView"
      v-on:close="modalClose"
      v-on:delete="deleteUser"
    ></modal_edit>
    <modal_delete v-if="modal_delete" v-bind:view="modalView" v-on:close="modalClose"></modal_delete>
  </div>
</template>

<script>
import modal_edit from './user_edit.vue'
import modal_delete from './user_delete.vue'

export default {
  data() {
    return {
      tableLoad: false,
      tableData: ['load'],
      modal: '',
      modal_delete: '',
      modalTitle: '',
      modalView: '',
    }
  },
  components: {
    modal_edit,
    modal_delete,
  },
  computed: {
    resource: function() {
      return this.$store.state.auth.resource
    },
  },
  mounted: function() {
    this.dataLoad()
  },
  methods: {
    modalClose: function(prm) {
      this.modal = ''
      this.modal_delete = ''
      if (prm == 'reload') {
        this.dataLoad()
      }
    },
    dataLoad: function() {
      var t = this
      t.$store.commit('loadingStartShow', true)
      this.$http.post(this.$store.state.apiUrl + 'user').then(
        response => {
          t.$store.commit('loadingStartShow', false)
          if (response.body.err > 0) {
            t.tableData = ['error']
          } else {
            t.tableData = response.body
          }
        },
        err => {
          console.log(err)
        },
      )
    },
    addUser: function() {
      this.modal = true
      this.modalView = []
    },
    editUser: function(view) {
      this.modal = true
      this.modalView = view
    },
    deleteUser: function(view) {
      console.log(view)
      this.modal_delete = true
      this.modalView = view
    },
    link: function(view) {
      this.$router.push(view)
    },
  },
}
</script>

<style></style>
